@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700,600);
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");

/* geral */

.tb-info-div .form-inline,
.tb-ocupacao-div .form-inline {
    display: inherit;
}

.top #tb-info_filter {
    position: inherit;
    margin: 30px;
}

.top #tb-ocupacao_filter {
    position: inherit;
    margin: 30px;
}

#tb-ocupacao tbody tr:hover {
    cursor: pointer;
    background-color: #005aff26;
}

.btn-ocupacao {
    display: flex;
    justify-content: center;
    border-radius: 1rem;
    border: 1px solid rgb(197, 197, 197);
    padding: 1rem;
    background-color: rgb(255, 255, 255);
}

.btn-ocupacao:hover {
    border: 1px solid #004578;
    color: #004578;
    transition: 1s ease;
}

.btn-parcelamento {
    display: flex;
    justify-content: center;
    border-radius: 1rem;
    border: 1px solid rgb(197, 197, 197);
    padding: 1rem;
    background-color: rgb(255, 255, 255);
}

.btn-parcelamento:hover {
    border: 1px solid #004578;
    color: #004578;
    transition: 1s ease;
}

.active-btn {
    border: 1px solid #004578;
    background-color: #004475;
    color: #ffffff;
}

.active-btn:hover {
    color: #ffffff;
}

.sombra {
    box-shadow: 2px 2px 3px gray;
}

.c-pointer {
    cursor: pointer;
}

.noresize {
    resize: none;
}

.width-fixo {
    min-width: 1600px !important;
}

.margin-top-bottom {
    margin: 10px 0;
    padding: 10px 0;
}

.border-top-bottom {
    border-style: solid;
    border-width: 1px 0;
    border-color: #00000020;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.texto-botao {
    padding: 10px;
    font-weight: 400;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
}

.texto {
    padding-left: 10px;
    font-weight: 400;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 20px;
}

.texto-pequeno {
    padding-left: 10px;
    font-weight: 400;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 14px;
}

.texto-medio {
    padding-left: 10px;
    font-weight: 400;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 16px;
}

.texto-grande {
    padding-left: 10px;
    font-weight: 400;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 25px;
}

.titulo {
    padding: 5px;
    font-weight: 500;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 30px;
}

#lock {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: #00000054;
    z-index: 9999;
    display: none;
}

/* fim geral */
/* loader */
.sender {
    color: white !important;
}

.sender span[class*="l-"] {
    background: white !important;
}

#loader {
    position: fixed;
    bottom: 30vh;
    left: 44vw;
    z-index: 99999;
}

#loader .text {
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-shadow: 0 0 8px black;
    color: white;
}

#loader span {
    display: block;
    margin: 0 auto;
}

#loader span[class*="l-"] {
    height: 6px;
    width: 6px;
    background: black;
    display: inline-block;
    margin: 12px 2px;

    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;

    -webkit-animation: loader 4s infinite;
    -webkit-animation-timing-function: cubic-bezier(0.030, 0.615, 0.995, 0.415);
    -webkit-animation-fill-mode: both;
    -moz-animation: loader 4s infinite;
    -moz-animation-timing-function: cubic-bezier(0.030, 0.615, 0.995, 0.415);
    -moz-animation-fill-mode: both;
    -ms-animation: loader 4s infinite;
    -ms-animation-timing-function: cubic-bezier(0.030, 0.615, 0.995, 0.415);
    -ms-animation-fill-mode: both;
    animation: loader 4s infinite;
    animation-timing-function: cubic-bezier(0.030, 0.615, 0.995, 0.415);
    animation-fill-mode: both;
}

#loader span.l-1 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -ms-animation-delay: 1s;
    -moz-animation-delay: 1s;
}

#loader span.l-2 {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    -ms-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
}

#loader span.l-3 {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
    -ms-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
}

#loader span.l-4 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    -ms-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
}

#loader span.l-5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    -ms-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
}

#loader span.l-6 {
    -webkit-animation-delay: 0;
    animation-delay: 0;
    -ms-animation-delay: 0;
    -moz-animation-delay: 0;
}

@-webkit-keyframes loader {
    0% {
        -webkit-transform: translateX(-60px);
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        -webkit-transform: translateX(60px);
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes loader {
    0% {
        -moz-transform: translateX(-60px);
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        -moz-transform: translateX(60px);
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-keyframes loader {
    0% {
        -transform: translateX(-60px);
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        -transform: translateX(60px);
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes loader {
    0% {
        -ms-transform: translateX(-60px);
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        -ms-transform: translateX(60px);
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

/* fim loader */
/* menu cinza gdf */
.barra-gdf {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    height: 28px;
    background-color: #515151;
    position: relative;
    width: 100% !important;
    border-bottom: 1px solid #bfbfbf;
}

.barra-gdf ul {
    list-style: none;
    float: right;
    margin-bottom: 0
}

.barra-gdf ul li {
    display: inline;
}

.barra-gdf ul li a {
    color: #ccc;
    line-height: 30px;
    margin-right: 40px;
    cursor: pointer;
    transition: all linear 0.2s;
    text-decoration: none;
}

.barra-gdf ul li a:hover {
    color: #fff;
    text-decoration: none;
}

.barra-gdf ul li:last-child a {
    margin-right: 0;
}

/* fim menu cinza gdf */
/* header */
.fundo-mapa {
    background-image: linear-gradient(to bottom right, rgb(3, 8, 12), rgb(16, 57, 79));
    background-size: 100vw 2000px;
    min-height: 800px;
}

.cabecalho {
    height: 150px;
    background-color: whitesmoke;
}

.cabecalho img {
    height: 100px;
    margin: auto 6vw
}

.cabecalho a {
    color: #565656;
    text-decoration: none;
    margin: auto 20px;
    transition: all linear 0.2s;
}

.cabecalho a:hover {
    color: black;
    text-shadow: 0 0 2px gray;
}

.cabecalho .fale-conosco {
    color: #ffffff;
    text-decoration: none;
    background-color: #fccf42;
    border-radius: 15px;
    padding: 20px 50px;
    margin: auto 20px;
}

.header {
    height: 100px;
    background-color: whitesmoke;
}

.header .logo-portal {
    padding-left: 7vw;
    margin: 6px 0;
    height: 85px;
}

#menu-top {
    padding-right: 10px;
}

.header-link,
.header-link a {
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: whitesmoke;
}

.header-link:hover,
.header-link a:hover {
    cursor: pointer;
    text-decoration: none;
    color: white;
    text-shadow: 0 0 2px white;
}

.dropdown-perfil {
    margin-top: 4px;
    background-color: rgb(3, 8, 12);
    color: #ffffff;
    padding: 5px 2px 3px 2px;
}

.dropdown-perfil h4 {
    padding: 3px 15px 3px 15px;
    font-size: 14px !important;
}

.dropdown-perfil h5 {
    padding: 3px 15px 3px 15px;
    font-size: 11px !important;
}

.dropdown-perfil a {
    margin: 0;
    color: #ccc;
}

.dropdown-perfil hr {
    margin: 2px 0 3px 0;
}

.dropdown .dropdown-header {
    color: #ffffff;
    font-size: 1em;
}

#pesquisar-local .input-group {
    width: 15vw;
}

#pesquisar-local .input-group button {
    background-color: #ffffff;
}

#pesquisar-local .input-group button:hover {
    background-color: #6c757d;
}

/* fim header */
/* mapas */

#mapa-brasilia2 {
    /* margin: auto; */
    /* width: 50%; */
    /* border: 3px solid green; */
    /* padding: 50px; */

    /* margin: 0; */
    position: relative;
    top: 10px;
    left: 50px;
    width: 100%;
    min-width: 1224px;
    min-height: 688.5px;
    /* align-items: center; */
    /* align-self: center; */
    /* align-content: center; */

}

#mapa-brasilia2 .img-link {
    position: absolute;
    display: block;
    /* padding-left: 120px; */
    width: 83vw;
    height: 78vh;
    z-index: 100;
    min-width: 1224px;
    min-height: 688.5px;
}

#mapa-brasilia2 .img-sat {
    position: absolute;
    display: block;
    /* padding-left: 120px; */
    width: 53vw;
    height: 70vh;
    z-index: 90;
    opacity: 0.75;
    min-width: 1224px;
    min-height: 688.5px;
}

#mapa-brasilia2 .mapa-ra {
    position: absolute;
    /* padding-left: 120px; */
    width: 83vw;
    height: 78vh;
    /* display: none; */
    z-index: 100;
    min-width: 1224px;
    min-height: 688.5px;
}

#mapa-brasilia2 .img-ra {
    position: absolute;
    display: block;
    /* padding-left: 120px; */
    width: 53vw;
    height: 70vh;
    z-index: 70;
    min-width: 1224px;
    min-height: 688.5px;
}

#mapa-brasilia2 area:hover {
    cursor: pointer;
}

#mapa-brasilia2 .img-arine {
    display: none;
    position: absolute;
    /* padding-left: 120px; */
    width: 83vw;
    height: 78vh;
    z-index: 95;
    min-width: 1224px;
    min-height: 688.5px;
}

#mapa-brasilia2 .img-aris {
    display: none;
    position: absolute;
    /* padding-left: 120px; */
    width: 83vw;
    height: 78vh;
    z-index: 95;
    min-width: 1224px;
    min-height: 688.5px;
}

#mapa-brasilia2 .img-pui-arine {
    display: none;
    position: absolute;
    /* padding-left: 120px; */
    width: 83vw;
    height: 78vh;
    z-index: 95;
    min-width: 1224px;
    min-height: 688.5px;
}

#mapa-brasilia2 .img-pui-aris {
    display: none;
    position: absolute;
    /* padding-left: 120px; */
    width: 83vw;
    height: 78vh;
    z-index: 95;
    min-width: 1224px;
    min-height: 688.5px;
}

/* fim mapas */
/* pin ra */

#nome-ra2 {
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 22px;
    color: white;
    display: none;
}

#nome-ra2 i {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #000;
    color: red;
    margin-right: 3px;
}

#nome-ra2 {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 196;
    color: white;
}

/* fim pin ra */
/* info */

#info {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 180px;
    left: 0px;
    width: 100%;
    min-width: 1600px;
    min-height: 100%;
    z-index: 110;
    background-color: #ffffff;
    display: none;
}

#info-legenda {
    display: none;
}

/* #tb-info{
  width: 100%;
} */
.voltar {
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #000;
    font-size: 30px;
    padding: 0 0 0 80px;
    padding-left: 10%;
}

.text-ocupacao {
    width: 20rem;
    /* border: 1px solid;
    padding: 2px 5px; */

    /* Both of the following are required for text-overflow */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.voltar:hover {
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: #000;
    /* font-size: 60px;
  padding: 20px 0 0 80px; */
    cursor: pointer;
}

.info-ra h1 {
    font-weight: 600;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    padding: 0;
    font-size: 27px;
    text-align: center;
}

.info-ra h2 {
    font-weight: 500;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    padding: 20px 0 0 0;
    font-size: 23px;
    text-align: center;
}

.info-ra h3 {
    font-weight: 500;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 20px;
}

.info-ra li {
    font-weight: 300;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 14px;
}

.info-ra img {
    margin: 5px 0 10px 0;
}

#tb-info tbody tr:hover {
    cursor: pointer;
    background-color: #005aff26;
}

.info-ra .selecionado {
    background-color: #005aff26;
}

#info-urbs ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

#info-urbs ul li {
    margin: 0 3px;
}

#info-diretriz-urb .fa-file-download,
#info-urbs .fa-file-download {
    font-size: 15;
    cursor: pointer;
    color: green;
}

#info-diretriz-urb .fa-file-download:hover,
#info-urbs .fa-file-download:hover {
    color: #11b911;
}

#info-parcelamento {
    margin-top: 10px;
}

#info-mapa img {
    cursor: zoom-in;
    border: 1px solid black;
    box-shadow: 0 0 5px black;
    width: 10%;

}

.info-mapa-max {
    cursor: zoom-out !important;
    width: 100% !important;
}

/* fim info */
/* Datatable custom */
.margin-top-custom {
    margin-top: 100px;
}

.tb-progresso .dataTables_filter {
    position: relative;
    top: -12px;
    left: 2vw;
}

.tb-progresso .dataTables_filter input {
    width: 400px;
    margin-left: 10px;
}

.top .dataTables_length {
    display: none;
}

.top .dataTables_filter {
    position: absolute;
    top: -100px;
    right: 10%;
}

.top .dataTables_filter input {
    width: 400px;
    margin-left: 10px;
}

.top-lista-arine .dataTables_length {
    display: none;
}

.top-lista-arine .dataTables_filter {
    position: relative;
    top: -20px;
    left: 100%;
}

.top-lista-arine .dataTables_filter input {
    width: 400px;
    margin-left: 10px;
}

.bot {
    max-width: 800px;
    position: relative;
    top: 20px;
    left: 10%;
}

.bot .pagination {
    max-width: 800px;
}

.bot .pagination a {
    font-weight: 300;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 18px;
    color: #004578;
    text-decoration: none;
}

.bot .pagination a:hover {
    color: #ffffff;
}

.bot .paginate_button a {
    border: 2px groove #004578;
    border-collapse: collapse;
    background-color: transparent;
    padding: 3px 8px 3px 8px;
}

.bot .paginate_button a:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.bot .paginate_button a:hover {
    background-color: #004578;
}

.bot .pagination .previous a {
    border-radius: 8px 0 0 8px;
}

.bot .active a {
    color: #ffffff;
    background-color: #004578;
}

.bot .pagination .next a {
    border-radius: 0 8px 8px 0;
}

/* fim Datatable custom */

/* Etapas */

#etapas {
    margin-left: 0vw;
}

#etapas .card {
    width: 10vw;
    min-width: 165px;
    height: calc(10vw * 1.2);
    min-height: 200px;
}
.subtext-card {
    position: absolute;
    /* top: 10px; */
    /* left: 20px; */
    /* width: 7vw; */
    /* text-align: center; */
    /* min-width: 100px; */
    /* font-weight: 400; */
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 12px;
    color: #ffffff; 
}
.text-card {
    position: absolute;
    /* top: 10px; */
    /* left: 20px; */
    /* width: 7vw; */
    text-align: center;
    /* min-width: 150px; */
    /* font-weight: 400; */
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #ffffff; 
}
.header-card {
    /* position: absolute; */
    /* top: 130px; */
    margin-left: -20px;
    /* width: 7vw; */
    /* text-align: center; */
    /* min-width: 100px; */
    /* font-weight: 400; */
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 26px;
    color: #ffffff; 
}
#etapas .card-text {
    position: absolute;
    top: 130px;
    left: 20px;
    width: 7vw;
    text-align: center;
    min-width: 100px;
    font-weight: 400;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #ffffff;
}

/* etapa 1 */
#etapas .etapa-1 {
    z-index: 10;
    position: relative;
    top: 20px;
    left: 0;
}

#etapas .etapa-1 .ativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/ativo/1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-1 .inativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/inativo/1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-1 .concluido {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/concluido/1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/* fim etapa 1 */
/* etapa 2 */
#etapas .etapa-2 {
    z-index: 9;
    position: relative;
    top: 20px;
    left: -50px;
}

#etapas .etapa-2 .ativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/ativo/2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-2 .inativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/inativo/2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-2 .concluido {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/concluido/2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/* fim etapa 2 */
/* etapa 3 */
#etapas .etapa-3 {
    z-index: 8;
    position: relative;
    top: 20px;
    left: -100px;
}

#etapas .etapa-3 .ativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/ativo/3.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-3 .inativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/inativo/3.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-3 .concluido {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/concluido/3.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/* fim etapa 3 */
/* etapa 4 */
#etapas .etapa-4 {
    z-index: 7;
    position: relative;
    top: 20px;
    left: -150px;
}

#etapas .etapa-4 .ativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/ativo/4.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-4 .inativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/inativo/4.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-4 .concluido {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/concluido/4.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/* fim etapa 4 */
/* etapa 5 */
#etapas .etapa-5 {
    z-index: 6;
    position: relative;
    top: 20px;
    left: -200px;
}

#etapas .etapa-5 .ativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/ativo/5.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-5 .inativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/inativo/5.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-5 .concluido {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/concluido/5.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/* fim etapa 5 */
/* etapa 6 */
#etapas .etapa-6 {
    z-index: 5;
    position: relative;
    top: 20px;
    left: -250px;
}

#etapas .etapa-6 .ativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/ativo/6.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-6 .inativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/inativo/6.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-6 .concluido {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/concluido/6.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/* fim etapa 6 */
/* etapa 7 */
#etapas .etapa-7 {
    z-index: 4;
    position: relative;
    top: 20px;
    left: -300px;
}

#etapas .etapa-7 .ativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/ativo/7.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-7 .inativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/inativo/7.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-7 .concluido {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/concluido/7.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/* fim etapa 7 */
/* etapa 8 */
#etapas .etapa-8 {
    z-index: 3;
    position: relative;
    top: 20px;
    left: -350px;
}

#etapas .etapa-8 .ativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/ativo/8.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-8 .inativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/inativo/8.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-8 .concluido {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/concluido/8.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/* fim etapa 8 */
/* etapa 9 */
#etapas .etapa-9 {
    z-index: 2;
    position: relative;
    top: 20px;
    left: -400px;
}

#etapas .etapa-9 .ativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/ativo/9.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-9 .inativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/inativo/9.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-9 .concluido {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/concluido/9.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/* fim etapa 9 */
/* etapa 10 */
#etapas .etapa-10 {
    z-index: 1;
    position: relative;
    top: 20px;
    left: -450px;
}

#etapas .etapa-10 .ativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/ativo/10.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-10 .inativo {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/inativo/10.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#etapas .etapa-10 .concluido {
    border: none;
    background-color: transparent;
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/etapas/concluido/10.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/* fim etapa 10 */
/* fim etapas */
/* Painel do usuÃ¡rio */
.navbar {
    margin-top: 2px;
    width: 100%;
    background-color: #515151;
    overflow: auto;
}

.navbar a {
    float: left;
    padding: 12px;
    color: white;
    text-decoration: none;
    font-size: 17px;
    width: 20%;
    /* Four links of equal widths */
    text-align: center;
}

.navbar a:hover {
    background-color: #ffffff;
    color: #515151;
}

.navbar a.active {
    background-color: #4CAF50;
}

.navbar a.active:hover {
    background-color: #ffffff;
    color: #4CAF50;
}

@media screen and (max-width: 500px) {
    .navbar a {
        float: none;
        display: block;
        width: 100%;
        text-align: left;
    }
}

/* fim Painel do usuÃ¡rio */
/* index usuÃ¡rio */
.index-usuario {
    margin-top: 70px;
    background-color: #f8f9fa;
    box-shadow: 0 0 3px grey;
}

/* fim index usuario */
/* Lateral Esquerda */
#ico-menu-E {
    /* z-index: 999; */
    /* display: block; */
    content: url('https://portaldaregularizacao.seduh.df.gov.br/img/icones/icone-menu.png');
    font-size: 4em;
    position: relative;
    left: 5px;
    top: 10px;
    font-size: 18px;
    width: 38px;
    float: left;
}

#ico-menu-E:hover~#lateral-E {
    -moz-transform: translate(110px, 0);
    -webkit-transform: translate(110px, 0);
    -o-transform: translate(110px, 0);
    transform: translate(110px, 0);
    padding-right: 0;
}

#lateral-E {
    padding: 0 50px 0 0;
    -moz-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    font-size: 1.2em;
    background-image: linear-gradient(to bottom right, #040b11, #0a212e);
    height: 100%;
    overflow: hidden;
    width: 110px;
    position: absolute;
    top: 0;
    left: -110px;
    z-index: 101;
}

#lateral-E:hover,
#lateral-E:focus,
#lateral-E:active {
    -moz-transform: translate(110px, 0);
    -webkit-transform: translate(110px, 0);
    -o-transform: translate(110px, 0);
    transform: translate(110px, 0);
    padding-right: 0;
}

/* Lateral Direita */
#ico-menu-D:hover~#lateral-D {
    -moz-transform: translate(-5VW, 0);
    -webkit-transform: translate(-5VW, 0);
    -o-transform: translate(-5VW, 0);
    transform: translate(-5VW, 0);
    padding-right: 0;
}

#lateral-D {
    margin-right: 20px;
    float: right;
    overflow: hidden;
    width: 256px;
    position: relative;
    top: -20px;
    z-index: 101;
}

#lateral-D h3 {
    font-size: 16px;
    text-align: center;
    color: #ccc;
}

#lateral-D p {
    margin: 0;
    padding: 10px;
    color: #ccc;
    text-align: justify;
}

#lateral-D .logo-geoportal {
    margin: 10px;
    width: 96px;
    min-width: 61px;
}

#lateral-D .logo-seduh {
    width: 208px;
    min-width: 61px;
}

#lateral-D .logo-ods {
    margin: 10px;
    width: 64px;
    min-width: 61px;
}

/* legenda */
#menu-E h3 {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
    color: #ccc;
}

.box-legenda {
    margin: 5px;
    width: 100px;
    height: 40px;
    z-index: 105;
    border: 1px solid black;
    font-size: 13px;
    color: white;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
}

.div-arine {
    background-color: #e09b0f;
}
.div-edit {
    background-color: #28165c;
}
.div-aris {
    background-color: #eae91a;
}

.div-pui-arine {
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/icones/ico-pui-arine.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #3c75f5;
}

.div-pui-aris {
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/icones/ico-pui-aris.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #ff7d7d;
}

.div-ra {
    background-image: url('https://portaldaregularizacao.seduh.df.gov.br/img/mapa-brasilia.png');
    background-size: 100% 100%;
    background-color: grey;
}

.div-arine:hover {
    cursor: pointer;
    border: 1px solid grey;
    text-shadow: 0 0 2px grey;
}

.div-edit:hover {
    cursor: pointer;
    border: 1px solid grey;
    text-shadow: 0 0 2px grey;
}

.div-aris:hover {
    cursor: pointer;
    border: 1px solid grey;
    text-shadow: 0 0 2px grey;
}

.div-pui-arine:hover {
    cursor: pointer;
    border: 1px solid grey;
    text-shadow: 0 0 2px grey;
}

.div-pui-aris:hover {
    cursor: pointer;
    border: 1px solid grey;
    text-shadow: 0 0 2px grey;
}

.div-ra:hover {
    cursor: pointer;
    border: 1px solid grey;
    text-shadow: 0 0 2px grey;
}

/* fim legenda */
/* Grafico */
#tb-dashboard {
    display: none;
}

.painel-resultado-externo {
    margin: 10px;
    padding: 2px;
    box-shadow: 2px 2px 3px gray;
}

.painel-resultado-interno {
    margin: auto;
    background-color: #232023;
    box-shadow: 2px 2px 3px gray;
    border-radius: 4px;
}

.painel-resultado-externo h4 {
    color: #232023;
    margin: 2px 10px;
}

.painel-resultado-interno h5 {
    color: white;
    padding: 5px;
    text-align: center;
}

.indicador-ocupacao {
    margin: 2px;
    box-shadow: 2px 2px 3px gray;
    color: #232023;
}

.indicador-ocupacao h4 {
    text-align: center;
    font-weight: bold;
}

.indicador-ocupacao span {
    width: 100%;
    background-color: #232023;
    box-shadow: 2px 2px 3px gray;
    border-radius: 4px;
    color: white;
}

.slidecontainer ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    columns: 2;
}

.slidecontainer .text-range {
    width: 40px;
    margin: 0 4px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.slidecontainer .pui {
    width: 80px;
}

.slidecontainer .n-urbano {
    width: 80px;
    font-size: 12px;
}

.slider-on {
    -webkit-appearance: none;
    width: 35px;
    height: 16px;
    border-radius: 10px;
    background: #99e5d8;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider-on::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #0fc2a2;
    cursor: pointer;
    box-shadow: 0 0 2px gray;
}

.slider-on::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #0fc2a2;
    cursor: pointer;
    box-shadow: 0 0 2px gray;
}

.slider-off {
    -webkit-appearance: none;
    width: 35px;
    height: 16px;
    border-radius: 10px;
    background: #d9d9d9;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider-off::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #fafafa;
    cursor: pointer;
    box-shadow: 0 0 2px gray;
}

.slider-off::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fafafa;
    cursor: pointer;
    box-shadow: 0 0 2px gray;
}

.area {
    display: flex;
    justify-content: center;
    padding: 4px;
}

.area button {
    margin: 0 10px;
    border-radius: 3px;
    border: 1px solid #9a28fc;
    padding: 10px 15px;
    color: #9a28fc;
    background-color: transparent;
    cursor: pointer;
}

.area button:hover {
    border: 1px solid white;
    color: white;
}

/** Here comes the chart*/
.chart {
    position: relative;
    width: 4.8vw;
    height: 4.8vw;

    border-radius: 50%;
    background-color: #222;

    overflow: hidden;
}

.chart .radio_chart {
    width: 100%;
    height: 100%;
    border-radius: 50%;

    position: absolute;
}

.chart .radio_chart.animate {
    animation-name: circled;
    animation-duration: 1s;
    animation-timing-function: linear;
    -moz-animation-name: circled;
    -moz-animation-duration: 1s;
    -moz-animation-timing-function: linear;
    -webkit-animation-name: circled;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    background: #a200ff;
}

.chart .cap {
    border-top: #666 3px solid;
    border-left: #666 2px solid;
    position: absolute;
    width: 100%;
    height: 100%;

    transform: scale(.8);

    background-color: #333;
    margin: 0 auto;
    border-radius: 50%;

    box-shadow: 2px 2px 10px rgba(0, 0, 0, .5);
}

.chart .value {
    color: white;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 120%;
    top: 34%;

    position: absolute;

    text-shadow: -2px -2px 5px #9a28fc, 2px 2px 5px #9a28fc, 0 0 50px #9a28fc;
}

/* The animation based on an hexagon
* its make the radial anim effet
*/
@keyframes circled {
    0% {
        clip-path: polygon(100% 0, 100% 0%, 100% 0%, 100% 0%, 100% 0%, 50% 50%);
    }

    25% {
        clip-path: polygon(100% 0, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 50%);
    }

    50% {
        clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0% 100%, 0 100%, 50% 50%);
    }

    75% {
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%, 0 0, 50% 50%);
    }

    100% {
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0, 100% 0, 50% 50%);
    }
}

@-webkit-keyframes circled {
    0% {
        -webkit-clip-path: polygon(100% 0, 100% 0%, 100% 0%, 100% 0%, 100% 0%, 50% 50%);
    }

    25% {
        -webkit-clip-path: polygon(100% 0, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 50%);
    }

    50% {
        -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0% 100%, 0 100%, 50% 50%);
    }

    75% {
        -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%, 0 0, 50% 50%);
    }

    100% {
        -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0, 100% 0, 50% 50%);
    }
}

@-moz-keyframes circled {
    0% {
        clip-path: polygon(100% 0, 100% 0%, 100% 0%, 100% 0%, 100% 0%, 50% 50%);
    }

    25% {
        clip-path: polygon(100% 0, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 50%);
    }

    50% {
        clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0% 100%, 0 100%, 50% 50%);
    }

    75% {
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%, 0 0, 50% 50%);
    }

    100% {
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0, 100% 0, 50% 50%);
    }
}

/* fim Grafico */
/*form cadastro */
#cadastro-form-parcelamento .form-checkbox {
    padding-top: 5px;
    font-weight: 400;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 18px;
}

.ui-menu-item {
    font-weight: 400;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
}

ui-menu,
ui-menu li {
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-adicionar {
    width: 200px;
    color: #ffffff;
    border: 1px solid #ced4da;
    background-color: #007bff;
}

.btn-remover {
    width: 200px;
    color: #ffffff;
    border: 1px solid #ced4da;
    background-color: #dc3545;
}

.btn-adicionar:hover {
    width: 200px;
    color: #007bff;
    background-color: #ffffff;
}

.btn-remover:hover {
    width: 200px;
    color: #dc3545;
    background-color: #ffffff;
}

.botao-adicionar {
    border: none;
    background-color: transparent;
    text-decoration: none !important;
    color: #000;
}

.botao-adicionar:hover {
    text-shadow: 0 0 1px grey;
    color: #000;
}

.botao-adicionar i {
    color: grey;
}

.botao-editar {
    margin: auto 5px;
    color: #000;
    text-decoration: none !important;
    border: none;
    background-color: transparent;
}

.botao-editar:hover {
    color: #000;
    text-shadow: 0 0 1px grey;
}

.botao-editar i {
    color: #4100ff
}

.botao-apagar {
    margin: auto 5px;
    color: #000;
    text-decoration: none !important;
    border: none;
    background-color: transparent;
}

.botao-apagar:hover {
    color: #000;
    text-shadow: 0 0 1px grey;
}

.botao-apagar i {
    color: red;
}

.botao-salvar {
    color: #3dc33d;
}

.botao-salvar:hover {
    color: #3dc33d;
    text-shadow: 0 0 1px grey;
}

.botao-cancelar {
    color: red;
}

.botao-cancelar:hover {
    color: red;
    text-shadow: 0 0 1px grey;
}

.botao-selecionar label::after {
    content: "Selecionar";
}

.botao-selecionar input:hover {
    cursor: pointer;
}

.body-collapse {
    margin-top: -5px;
    background-color: #00a1ff26;
    border: 1px solid #95cdf5;
    border-radius: 0 0 5px 5px;
    color: #31708f;
}

.botao-collapse {
    margin-top: 14px;
    margin-left: 15px;
    border: none;
    background-color: transparent;
    content: f010;
    color: #545454;
    text-decoration: none !important;
    transition: all linear 0.2s;
}

.botao-collapse:hover,
.botao-collapse:focus {
    color: #151515;
    text-shadow: 0 0 1px grey;
    outline: none;
}

#tb-arine {
    display: none;
}

#tb-arine a {
    text-decoration: none;
    color: darkslategray;
}

#tb-aris {
    display: none;
}

#tb-aris a {
    text-decoration: none;
    color: darkslategray;
}

.panel-title>button:before {
    float: left !important;
    font-family: FontAwesome;
    content: "\f010";
    padding-right: 5px;
    color: red;
}

.panel-title>button.collapsed:before {
    float: left !important;
    content: "\f00e";
    color: #3dc33d;
}

.panel-title>button:hover,
.panel-title>button:active,
.panel-title>button:focus {
    text-decoration: none;
}

.posicao-canto-superior {
    position: absolute;
    top: 0;
    left: -200px;
    z-index: 100;
}

/* PÃGINA INICIAL*/
.img-carousel {
    background-size: 100% auto;
    background-position: center;
    height: 486px;
    width: 100%
}

.carousel-div {
    height: 486px;
    width: 100%
}

.cartilhas {
    margin: auto;
    padding: 100px 0;
}

.cartilhas a {
    width: 25%;
    text-decoration: none;
}

.cartilhas img {
    width: 100%;
    -webkit-filter: grayscale(20%);
    filter: grayscale(20%);
}

.cartilhas img:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

.servicos-titulo {
    font-weight: 400;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 30px;
    color: #555555;
    font-weight: bold;
}

.servicos {
    padding-bottom: 20px;
}

.servicos div {
    margin: 5px 0;
}

.servicos a {
    text-decoration: none;
    font-size: 20px;
    color: gray;
}

.servicos a i {

    background-color: #656565;
    color: #ffffff;
    font-size: 20px;
    border-radius: 5px;
    padding: 6px 11px;
}

.servicos a:hover {
    color: black;
}

.link-mapa a {
    text-decoration: none;
}

.link-mapa a:hover .link-mapa-4 {
    background-color: #116ca9;

}

.link-mapa-1 {
    margin: auto 20px;
    height: 80px
}

.link-mapa-2 {
    background-color: #004475;
    height: 80px
}

.link-mapa-2 img {
    margin-right: 5px;
    width: 55px;
    margin: 12.5px;
}

.link-mapa-3 {
    background-color: #0097c0;
    width: 5px;
    height: 100%
}

.link-mapa-4 {
    transition: all linear 0.2s;
    background-color: #005085;
    height: 80px;
    width: 400px;
}

.link-mapa-5 {
    color: #ffffff;
    margin: 25px;
}

.rodape {
    color: #ffffff
}

.rodape a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 400;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 20px;
}

.rodape a:hover {
    text-shadow: 0 0 2px #ffffff;
}

.rodape p {
    font-weight: 400;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 16px;
}

.balao {
    font-size: 60px;
}

.info {
    color: #000;
    position: relative;
    left: -35px;
    bottom: -25px;
}

.balao-consulta {
    font-size: 60px;
    position: relative;

}

.info-consulta {
    color: #000;
    position: relative;
    left: -35px;
    bottom: -25px;
}

.teste {
    margin-right: 20px;
}

.sobre {
    padding: 30px 0;
    text-align: justify;
    color: #565656;
}

/* FIM PÃGINA INICIAL*/
/* PAINEL DE MENSAGENS */
.msg-heading-1 {
    color: white;
    background-color: #2f75b5;
}

.msg-heading-2 {
    color: white;
    background-color: #bf8f00;
}

.msg-heading-3 {
    color: white;
    background-color: #548235;
}

.msg-heading-4 {
    color: white;
    background-color: #c61111;
}

.msg-collapse-1 {
    margin-top: -5px;
    color: white;
    background-color: #5b9bd5;
    border-radius: 0 0 5px 5px;
    border: 1px solid #2f75b5;
}

.msg-collapse-2 {
    margin-top: -5px;
    color: black;
    background-color: #ffc000;
    border: 1px solid #bf8f00;
    border-radius: 0 0 5px 5px;
}

.msg-collapse-3 {
    margin-top: -5px;
    color: white;
    background-color: #70ad47;
    border: 1px solid #548235;
}

.msg-collapse-4 {
    margin-top: -5px;
    color: white;
    background-color: #f55b5b;
    border: 1px solid #c61111;
    border-radius: 0 0 5px 5px;
}

.msg-collapse-1 hr {
    width: 95%;
    color: #31708f;
    background-color: #2f75b5;
}

.msg-collapse-2 hr {
    width: 95%;
    color: #31708f;
    background-color: #bf8f00;
}

.msg-collapse-3 hr {
    width: 95%;
    color: #31708f;
    background-color: #548235;
}

.msg-collapse-4 hr {
    width: 95%;
    color: #31708f;
    background-color: #c61111;
}

.msg-card i {
    color: whitesmoke;
    transition: all linear 0.1s;
}

.msg-card i:hover {
    color: white;
    cursor: pointer;
    text-shadow: 0px 0px 2px #ffffff;
}

#modalResponder .modal-msg,
#modalNota .modal-msg {
    padding: 10px;
    background-color: whitesmoke;
    border-radius: 15px;
}

#modalResponder .modal-msg .btn-cancelar,
#modalNota .modal-msg .btn-cancelar,
#modalNota .modal-msg .btn-apagarNota {
    background-color: white;
    margin: 5px;
    border: 1px solid #c61111;
    border-radius: 10px;
    color: #f55b5b;
    transition: all linear 0.1s;
}

#modalResponder .modal-msg .btn-cancelar:hover,
#modalNota .modal-msg .btn-cancelar:hover,
#modalNota .modal-msg .btn-apagarNota:hover {
    color: #ffffff;
    background-color: #f55b5b;
    cursor: pointer;
    box-shadow: 2px 2px 2px gray;
}

#modalResponder .modal-msg .btn-cancelar:active,
#modalNota .modal-msg .btn-cancelar:active,
#modalNota .modal-msg .btn-apagarNota:active {
    box-shadow: none;
}

#modalResponder .modal-msg .btn-enviar {
    background-color: white;
    margin: 5px;
    border: 1px solid #2f75b5;
    border-radius: 10px;
    color: #5b9bd5;
    transition: all linear 0.1s;
}

#modalResponder .modal-msg .btn-enviar:hover {
    color: #ffffff;
    background-color: #5b9bd5;
    cursor: pointer;
    box-shadow: 2px 2px 2px gray;
}

#modalResponder .modal-msg .btn-enviar:active {
    box-shadow: none;
}

#modalNota .modal-msg .btn-addNota {
    background-color: white;
    margin: 5px;
    border: 1px solid #2f75b5;
    border-radius: 10px;
    color: #5b9bd5;
    transition: all linear 0.1s;
}

#modalNota .modal-msg .btn-addNota:hover {
    color: #ffffff;
    background-color: #5b9bd5;
    cursor: pointer;
    box-shadow: 2px 2px 2px gray;
}

#modalNota .modal-msg .btn-addNota:active {
    box-shadow: none;
}

#modalNota .modal-msg .btn-editarNota,
#modalNota .modal-msg .btn-editar {
    background-color: #ffffff;
    margin: 5px;
    border: 1px solid #bf8f00;
    border-radius: 10px;
    color: #ffc000;
    transition: all linear 0.1s;
}

#modalNota .modal-msg .btn-editarNota:hover,
#modalNota .modal-msg .btn-editar:hover {
    color: #ffffff;
    background-color: #ffc000;
    cursor: pointer;
    box-shadow: 2px 2px 2px gray;
}

#modalNota .modal-msg .btn-editarNota:active,
#modalNota .modal-msg .btn-editar:active {
    box-shadow: none;
}

/* LEGISLAÃ‡ÃƒO DEREGULRIZAÃ‡ÃƒO FUNDIÃRIA */
#legislacao-federal h2,
#legislacao-distrital h2,
#ps-legislacao-federal h2,
#ps-legislacao-distrital h2,
#lt-federal h2,
#lt-distrital h2,
#sistema-viario-cicloviario h2 {
    font-weight: bold;
}

#legislacao-federal .card-header:hover,
#legislacao-distrital .card-header:hover,
#ps-legislacao-federal .card-header:hover,
#ps-legislacao-distrital .card-header:hover,
#lt-federal .card-header:hover,
#lt-distrital .card-header:hover,
#sistema-viario-cicloviario .card-header:hover {
    cursor: pointer;
    background-color: #0000001a;
}

/* FIM LEGISLAÃ‡ÃƒO DEREGULRIZAÃ‡ÃƒO FUNDIÃRIA */
/* CARTILHA REGULARIZAÃ‡ÃƒO */
.cartilha-download {
    margin: 0 10px;
    color: gray;
}

.cartilha-download:hover {
    text-decoration: none;
    color: #007bff;
}

#cartilha-regularizacao li {
    background-color: whitesmoke;
    border: 1px solid #bfbfbf;
    border-radius: 5px 5px 0 0;
}

#cartilha-regularizacao a {
    color: gray;
}

#cartilha-regularizacao a:hover {
    color: black;
    text-shadow: 0 0 2px gray;
}

#cartilha-regularizacao .active {
    color: #007bff;
    text-shadow: 0 0 1px #007bff;
}

#cartilha-regularizacao .active:hover {
    color: #007bff;
    text-shadow: 0 0 1px #007bff;
}

#cartilha-regularizacao-tab {
    border: 1px solid #bfbfbf;
    border-radius: 0 0 5px 5px;
}

.botao-next {
    text-decoration: none !important;
    right: 0;
    position: absolute;
    top: 10px;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    width: 15%;
    color: #fff;
    text-align: right;
    padding-left: 5%;
}

.botao-next span,
.botao-prev span {
    font-size: 20px;
    color: black;
    text-shadow: 0 0 1px black;
}

.botao-prev {
    text-decoration: none !important;
    left: 2%;
    position: absolute;
    top: 10px;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    width: 15%;
    color: #fff;
    text-align: center;
}

/* FIM CARTILHA REGULARIZAÃ‡ÃƒO */
/* Tempo de anÃ¡lise */
.ta-editar:hover {
    color: #1ecc46 !important;
}

.ta-apagar:hover {
    color: #ff0018 !important;
}

/* fim Tempo de anÃ¡lise */
@media (min-width: 1601px) and (max-width:1700px) {
    .texto {
        font-size: 17px;
    }

    .texto-pequeno {
        font-size: 11px;
    }

    .texto-medio {
        font-size: 13px;
    }

    .texto-grande {
        font-size: 23px;
    }

    .titulo {
        font-size: 27px;
    }

    .cabecalho a {
        margin: auto 10px;
    }

    .cabecalho .fale-conosco {
        padding: 10px 40px;
        margin: auto 10px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #lateral-D {
        margin-right: 0;
    }

    #etapas .card-text {
        font-size: 15px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
}

@media (min-width: 1501px) and (max-width:1600px) {
    .fundo-mapa {
        background-size: 1600px 2000px;
    }

    .texto {
        font-size: 17px;
    }

    .texto-pequeno {
        font-size: 11px;
    }

    .texto-medio {
        font-size: 13px;
    }

    .texto-grande {
        font-size: 23px;
    }

    .titulo {
        font-size: 27px;
    }

    .cabecalho a {
        margin: auto 5px;
    }

    .cabecalho .fale-conosco {
        padding: 5px 30px;
        margin: auto 5px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #lateral-D {
        margin-right: -20px;
    }

    #etapas .card-text {
        font-size: 14px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
}

@media (min-width: 1401px) and (max-width:1500px) {
    .barra-gdf a {
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        height: 28px;
        background-color: #515151;
        position: relative;
        width: 100% !important;
        border-bottom: 1px solid #bfbfbf;
        margin: 0 5px !important;
    }

    .fundo-mapa {
        background-size: 1600px 2000px;
    }

    .texto {
        font-size: 17px;
    }

    .texto-pequeno {
        font-size: 11px;
    }

    .texto-medio {
        font-size: 13px;
    }

    .texto-grande {
        font-size: 23px;
    }

    .titulo {
        font-size: 27px;
    }

    .cabecalho a {
        margin: auto 5px;
    }

    .cabecalho .fale-conosco {
        padding: 5px 15px;
        margin: auto 5px;
    }

    .rodape div {
        margin-bottom: 20px;
    }

    .rodape img {
        width: 90%;
    }

    .rodape p {
        font-size: 18px;
        margin-bottom: 8px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #lateral-D {
        margin-right: -200px;
    }

    #etapas .card-text {
        font-size: 14px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
}

@media (min-width: 1301px) and (max-width:1400px) {
    .barra-gdf a {
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        height: 28px;
        background-color: #515151;
        position: relative;
        width: 100% !important;
        border-bottom: 1px solid #bfbfbf;
        margin: 0 5px !important;
    }

    .fundo-mapa {
        background-size: 1600px 2000px;
    }

    .texto {
        font-size: 15px;
    }

    .texto-pequeno {
        font-size: 9px;
    }

    .texto-medio {
        font-size: 12px;
    }

    .texto-grande {
        font-size: 21px;
    }

    .titulo {
        font-size: 25px;
    }

    .cabecalho a {
        margin: auto 5px;
    }

    .cabecalho .fale-conosco {
        padding: 5px 15px;
        margin: auto 5px;
    }

    .rodape div {
        margin-bottom: 20px;
    }

    .rodape img {
        width: 90%;
    }

    .rodape p {
        font-size: 15px;
        margin-bottom: 8px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #lateral-D {
        margin-right: -200px;
    }

    #etapas .card-text {
        font-size: 14px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
}

@media (min-width: 1201px) and (max-width:1300px) {
    .barra-gdf a {
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        height: 28px;
        background-color: #515151;
        position: relative;
        width: 100% !important;
        border-bottom: 1px solid #bfbfbf;
        margin: 0 5px !important;
    }

    .fundo-mapa {
        background-size: 1600px 2000px;
    }

    .texto {
        font-size: 15px;
    }

    .texto-pequeno {
        font-size: 9px;
    }

    .texto-medio {
        font-size: 12px;
    }

    .texto-grande {
        font-size: 21px;
    }

    .titulo {
        font-size: 25px;
    }

    .cabecalho a {
        margin: auto;
    }

    .cabecalho .fale-conosco {
        padding: 5px 30px;
        margin: auto;
    }

    .rodape div {
        margin-bottom: 20px;
    }

    .rodape img {
        width: 90%;
    }

    .rodape p {
        font-size: 13px;
        margin-bottom: 8px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #lateral-D {
        margin-right: -200px;
    }

    #etapas .card-text {
        font-size: 14px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
}

@media (min-width: 1101px) and (max-width:1200px) {
    .barra-gdf a {
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        height: 28px;
        background-color: #515151;
        position: relative;
        width: 100% !important;
        border-bottom: 1px solid #bfbfbf;
        margin: 0 5px !important;
    }

    #gdf-acessibilidade {
        display: none;
    }

    .fundo-mapa {
        background-size: 1600px 2000px;
    }

    .texto {
        font-size: 15px;
    }

    .texto-pequeno {
        font-size: 9px;
    }

    .texto-medio {
        font-size: 12px;
    }

    .texto-grande {
        font-size: 21px;
    }

    .titulo {
        font-size: 25px;
    }

    .cabecalho a {
        margin: auto;
    }

    .cabecalho .fale-conosco {
        padding: 5px 30px;
        margin: auto;
    }

    .rodape div {
        margin-bottom: 20px;
    }

    .rodape img {
        width: 90%;
    }

    .rodape p {
        font-size: 13px;
        margin-bottom: 8px;
    }

    .balao-info {
        position: absolute;
        top: 0;
        font-size: 80px;
    }

    .i-info {
        position: absolute;
        top: 16px;
        left: 32px;
        color: black;
        font-size: 40px;
    }

    .texto-info {
        position: absolute;
        top: 20px;
        left: 95px;
    }

    .texto-info p {
        font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
        margin: 0;
    }

    .link-mapa-1 {
        height: 80px
    }

    .link-mapa-2 {
        height: 80px
    }

    .link-mapa-4 {
        height: 80px;
        width: 350px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #lateral-D {
        margin-right: -200px;
    }

    #etapas .card-text {
        font-size: 14px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
    /* cartilha regularizaÃ§Ã£o */
    .botao-next {
        padding-left: 4%;
    }

    /* fim cartilha regularizaÃ§Ã£o */
}

@media (min-width: 1001px) and (max-width:1100px) {
    .barra-gdf a {
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        height: 28px;
        background-color: #515151;
        position: relative;
        width: 100% !important;
        border-bottom: 1px solid #bfbfbf;
        margin: 0 5px !important;
    }

    #gdf-acessibilidade {
        display: none;
    }

    .fundo-mapa {
        background-size: 1600px 2000px;
    }

    .texto {
        font-size: 15px;
    }

    .texto-pequeno {
        font-size: 9px;
    }

    .texto-medio {
        font-size: 12px;
    }

    .texto-grande {
        font-size: 21px;
    }

    .titulo {
        font-size: 25px;
    }

    .cabecalho a {
        margin: auto;
    }

    .cabecalho .fale-conosco {
        padding: 5px 30px;
        margin: auto;
    }

    .rodape div {
        margin-bottom: 20px;
    }

    .rodape img {
        width: 100%;
    }

    .rodape p {
        font-size: 13px;
        margin-bottom: 8px;
    }

    .balao-info {
        position: absolute;
        top: -20px;
        font-size: 50px;
    }

    .i-info {
        position: absolute;
        top: -6px;
        left: 22px;
        color: black;
        font-size: 20px;
    }

    .texto-info {
        position: absolute;
        top: 20px;
        left: 45px;
    }

    .texto-info p {
        font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
        margin: 0;
    }

    .link-mapa-1 {
        height: 80px
    }

    .link-mapa-2 {
        height: 80px
    }

    .link-mapa-4 {
        height: 80px;
        width: 300px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #lateral-D {
        margin-right: -200px;
    }

    #etapas .card-text {
        font-size: 14px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
    /* cartilha regularizaÃ§Ã£o */
    .botao-next {
        padding-left: 4%;
    }

    /* fim cartilha regularizaÃ§Ã£o */
}

@media (min-width: 901px) and (max-width:1000px) {
    .barra-gdf a {
        font-family: "Montserrat", sans-serif;
        font-size: 10px;
        height: 28px;
        background-color: #515151;
        position: relative;
        width: 100% !important;
        border-bottom: 1px solid #bfbfbf;
        margin: 0 5px !important;
    }

    #gdf-acessibilidade {
        display: none;
    }

    .fundo-mapa {
        background-size: 1600px 2000px;
    }

    .texto {
        font-size: 15px;
    }

    .texto-pequeno {
        font-size: 9px;
    }

    .texto-medio {
        font-size: 12px;
    }

    .texto-grande {
        font-size: 21px;
    }

    .titulo {
        font-size: 25px;
    }

    .cabecalho a {
        margin: auto;
    }

    .cabecalho .fale-conosco {
        padding: 5px 30px;
        margin: auto;
    }

    .rodape div {
        margin-bottom: 20px;
    }

    .rodape img {
        width: 100%;
    }

    .rodape p {
        font-size: 13px;
        margin-bottom: 8px;
    }

    #div-fale-secretaria {
        padding: 0;
    }

    #div-acesso-informacoes {
        position: relative;
        top: -20px;
        left: -80px;
        width: 150px;
    }

    #div-acesso-informacoes i {
        display: none;
    }

    .img-carousel,
    .carousel-div {
        height: 400px;
    }

    .link-mapa-1 {
        height: 60px
    }

    .link-mapa-2 {
        height: 60px
    }

    .link-mapa-2 img {
        width: 41px;
    }

    .link-mapa-4 {
        height: 60px;
        width: 250px;
    }

    .link-mapa-5 {
        font-size: 16px;
    }

    .servicos div {
        margin: 10px 0;
    }

    .servicos a {
        font-size: 22px;
    }

    .servicos a i {
        font-size: 22px;
        border-radius: 10px;
        padding: 8px 12px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #lateral-D {
        margin-right: -200px;
    }

    #etapas .card-text {
        font-size: 14px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
    /* cartilha regularizaÃ§Ã£o */
    .botao-next {
        padding-left: 1%;
    }

    /* fim cartilha regularizaÃ§Ã£o */
}

@media (min-width: 801px) and (max-width:900px) {
    .barra-gdf a {
        font-family: "Montserrat", sans-serif;
        font-size: 9px;
        height: 28px;
        background-color: #515151;
        position: relative;
        width: 100% !important;
        border-bottom: 1px solid #bfbfbf;
        margin: 0 5px !important;
    }

    #gdf-acessibilidade {
        display: none;
    }

    .fundo-mapa {
        background-size: 1600px 2000px;
    }

    .texto {
        font-size: 15px;
    }

    .texto-pequeno {
        font-size: 9px;
    }

    .texto-medio {
        font-size: 12px;
    }

    .texto-grande {
        font-size: 21px;
    }

    .titulo {
        font-size: 25px;
    }

    .cabecalho img {
        width: 300px;
    }

    .cabecalho a {
        margin: auto;
    }

    .cabecalho .fale-conosco {
        padding: 5px 30px;
        margin: auto;
    }

    .rodape div {
        margin-bottom: 20px;
    }

    .rodape img {
        padding-top: 20px;
        width: 100%;
    }

    .rodape p {
        font-size: 13px;
        margin-bottom: 8px;
    }

    #div-fale-secretaria {
        padding: 0;
    }

    #div-acesso-informacoes {
        position: relative;
        top: 0px;
        left: -100px;
        width: 150px;
    }

    #div-acesso-informacoes i {
        display: none;
    }

    .img-carousel,
    .carousel-div {
        height: 400px;
    }

    .link-mapa-1 {
        height: 60px
    }

    .link-mapa-2 {
        height: 60px
    }

    .link-mapa-2 img {
        width: 41px;
    }

    .link-mapa-4 {
        height: 60px;
        width: 250px;
    }

    .link-mapa-5 {
        font-size: 16px;
    }

    .servicos div {
        margin: 10px 0;
    }

    .servicos a {
        font-size: 22px;
    }

    .servicos a i {
        font-size: 22px;
        border-radius: 10px;
        padding: 8px 12px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #lateral-D {
        margin-right: -200px;
    }

    #etapas .card-text {
        font-size: 14px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
    /* cartilha regularizaÃ§Ã£o */
    .botao-next {
        padding-left: 1%;
    }

    /* fim cartilha regularizaÃ§Ã£o */
}

@media (min-width: 701px) and (max-width:800px) {
    .barra-gdf a {
        font-family: "Montserrat", sans-serif;
        font-size: 8px;
        height: 28px;
        background-color: #515151;
        position: relative;
        width: 100% !important;
        border-bottom: 1px solid #bfbfbf;
        margin: 0 5px !important;
    }

    #gdf-acessibilidade {
        display: none;
    }

    .gdf-smart {
        display: none;
    }

    .fundo-mapa {
        background-size: 1600px 2000px;
    }

    .texto {
        font-size: 15px;
    }

    .texto-pequeno {
        font-size: 9px;
    }

    .texto-medio {
        font-size: 12px;
    }

    .texto-grande {
        font-size: 18px;
    }

    .titulo {
        font-size: 25px;
    }

    .cabecalho img {
        padding: auto;
        width: 400px;
    }

    .cabecalho a {
        margin: auto;
        padding-bottom: 5px;
    }

    .cabecalho .fale-conosco {
        padding: 5px 15px;
        margin: auto;
    }

    .rodape div {
        margin-bottom: 20px;
    }

    .rodape img {
        width: 100%;
    }

    .rodape p {
        font-size: 15px;
        margin-bottom: 8px;
    }

    #div-acesso-informacoes {
        position: relative;
        top: 0px;
        left: -100px;
        width: 150px;
    }

    #div-acesso-informacoes i {
        display: none;
    }

    #div-fale-secretaria {
        padding: 0;
    }

    .img-carousel,
    .carousel-div {
        height: 350px;
    }

    .link-mapa a {
        margin: 5px auto;
    }

    .link-mapa-1 {
        height: 80px
    }

    .link-mapa-2 {
        height: 80px
    }

    .link-mapa-2 img {
        width: 50px;
    }

    .link-mapa-4 {
        height: 80px;
        width: 400px;
    }

    .servicos div {
        margin: 10px 0;
    }

    .servicos a {
        font-size: 28px;
    }

    .servicos a i {
        font-size: 28px;
        border-radius: 10px;
        padding: 8px 12px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #menu-top {
        display: none;
    }

    #lateral-D {
        margin-right: -200px;
    }

    #etapas .card-text {
        font-size: 14px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
    /* cartilha regularizaÃ§Ã£o */
    .botao-next,
    .botao-prev {
        top: 4px;
        padding: 0;
    }

    .botao-next span,
    .botao-prev span {
        font-size: 15px;
    }

    /* fim cartilha regularizaÃ§Ã£o */
}

@media (min-width: 601px) and (max-width:700px) {
    .barra-gdf a {
        font-family: "Montserrat", sans-serif;
        font-size: 8px;
        height: 28px;
        background-color: #515151;
        position: relative;
        width: 100% !important;
        border-bottom: 1px solid #bfbfbf;
        margin: 0 5px !important;
    }

    #gdf-acessibilidade {
        display: none;
    }

    .gdf-smart {
        display: none;
    }

    .fundo-mapa {
        background-size: 1600px 2000px;
    }

    .texto {
        font-size: 15px;
    }

    .texto-pequeno {
        font-size: 9px;
    }

    .texto-medio {
        font-size: 12px;
    }

    .texto-grande {
        font-size: 18px;
    }

    .titulo {
        font-size: 25px;
    }

    .cabecalho img {
        padding: auto;
        height: 50px;
    }

    .cabecalho a {
        margin: auto;
        padding-bottom: 5px;
    }

    .cabecalho .fale-conosco {
        padding: 5px 15px;
        margin: auto;
    }

    .rodape div {
        margin-bottom: 20px;
    }

    .rodape img {
        width: 100%;
    }

    .rodape p {
        font-size: 15px;
        margin-bottom: 8px;
    }

    #div-acesso-informacoes {
        position: relative;
        top: -100px;
        left: 55%;
        width: 150px;
    }

    #div-fale-secretaria {
        padding: 0;
    }

    .img-carousel,
    .carousel-div {
        height: 300px;
    }

    .link-mapa a {
        margin: 5px auto;
    }

    .link-mapa-1 {
        height: 80px
    }

    .link-mapa-2 {
        height: 80px
    }

    .link-mapa-2 img {
        width: 50px;
    }

    .link-mapa-4 {
        height: 80px;
        width: 400px;
    }

    .servicos div {
        margin: 10px 0;
    }

    .servicos a {
        font-size: 28px;
    }

    .servicos a i {
        font-size: 28px;
        border-radius: 10px;
        padding: 8px 12px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #menu-top {
        display: none;
    }

    #lateral-D {
        margin-right: -200px;
    }

    #etapas .card-text {
        font-size: 14px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
    /* cartilha regularizaÃ§Ã£o */
    .botao-next,
    .botao-prev {
        top: 4px;
        padding: 0;
    }

    .botao-next span,
    .botao-prev span {
        font-size: 15px;
    }

    /* fim cartilha regularizaÃ§Ã£o */
}

@media (min-width: 501px) and (max-width:600px) {
    .barra-gdf a {
        font-family: "Montserrat", sans-serif;
        font-size: 8px;
        height: 28px;
        background-color: #515151;
        position: relative;
        width: 100% !important;
        border-bottom: 1px solid #bfbfbf;
        margin: 0 5px !important;
    }

    #gdf-acessibilidade {
        display: none;
    }

    .gdf-smart {
        display: none;
    }

    .fundo-mapa {
        background-size: 1600px 2000px;
    }

    .texto {
        font-size: 15px;
    }

    .texto-pequeno {
        font-size: 9px;
    }

    .texto-medio {
        font-size: 12px;
    }

    .texto-grande {
        font-size: 18px;
    }

    .titulo {
        font-size: 25px;
    }

    .cabecalho img {
        padding: auto;
        height: 50px;
    }

    .cabecalho a {
        margin: auto;
        padding-bottom: 5px;
    }

    .cabecalho .fale-conosco {
        padding: 5px 15px;
        margin: auto;
    }

    .sobre {
        padding: 30px 15px;
    }

    .rodape div {
        margin-bottom: 20px;
    }

    .rodape img {
        width: 80%;
    }

    .rodape p {
        font-size: 15px;
        margin-bottom: 8px;
    }

    #div-acesso-informacoes {
        position: relative;
        top: -200px;
        left: 200px;
        width: 150px;
    }

    #div-fale-secretaria {
        padding: 0;
    }

    .img-carousel,
    .carousel-div {
        height: 250px;
    }

    link-mapa div {
        margin: 0 !important;
    }

    .link-mapa a {
        margin: 5px auto;
    }

    .link-mapa-1 {
        height: 50px
    }

    .link-mapa-2 {
        height: 50px
    }

    .link-mapa-2 img {
        width: 37.5px;
        margin: 5px;
    }

    .link-mapa-4 {
        height: 50px;
        width: 350px;
    }

    .link-mapa-5 {
        margin: 15px;
    }

    .cartilhas {
        margin: auto;
        padding: 80px 0;
    }

    .servicos div {
        margin: 10px 0;
    }

    .servicos a {
        font-size: 28px;
    }

    .servicos a i {
        font-size: 28px;
        border-radius: 10px;
        padding: 8px 12px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #menu-top {
        display: none;
    }

    #lateral-D {
        margin-right: -200px;
    }

    #etapas .card-text {
        font-size: 14px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
    /* cartilha regularizaÃ§Ã£o */
    .botao-next,
    .botao-prev {
        top: 4px;
        padding: 0;
    }

    .botao-next span,
    .botao-prev span {
        font-size: 13px;
    }

    /* fim cartilha regularizaÃ§Ã£o */
}

@media (max-width:500px) {
    .barra-gdf a {
        font-family: "Montserrat", sans-serif;
        font-size: 8px;
        height: 28px;
        background-color: #515151;
        position: relative;
        width: 100% !important;
        border-bottom: 1px solid #bfbfbf;
        margin: 0 5px !important;
    }

    #gdf-acessibilidade {
        display: none;
    }

    .gdf-smart {
        display: none;
    }

    .fundo-mapa {
        background-size: 1600px 2000px;
    }

    .texto {
        font-size: 15px;
    }

    .texto-pequeno {
        font-size: 9px;
    }

    .texto-medio {
        font-size: 12px;
    }

    .texto-grande {
        font-size: 16px;
    }

    .titulo {
        font-size: 25px;
    }

    .cabecalho img {
        padding: auto;
        height: 50px;
    }

    .cabecalho a {
        margin: auto;
        padding-bottom: 5px;
    }

    .cabecalho .fale-conosco {
        padding: 5px 15px;
        margin: auto;
    }

    .sobre {
        padding: 30px 15px;
    }

    /* RODAPE */
    .rodape div {
        margin-bottom: 20px;
    }

    .rodape img {
        width: 100%;
    }

    .rodape p {
        font-size: 15px;
        margin-bottom: 8px;
    }

    #div-img-gdf {
        position: relative;
        top: -20px;
    }

    #div-fale-secretaria {
        position: relative;
        top: -15px;
    }

    #div-info-seduh {
        position: relative;
        top: 0px;
    }

    #div-acesso-informacoes {
        position: relative;
        top: -215px;
        left: 35%;
        width: 150px;
    }

    /* FIM RODAPE */
    .img-carousel,
    .carousel-div {
        height: 200px;
    }

    #link-regularizacao,
    #link-parcelamento {
        width: 360px;
        position: relative;
        left: 15px;
    }

    .link-mapa a {
        margin: 5px 0;
        width: 340px;
    }

    .link-mapa-1 {
        height: 50px;
    }

    .link-mapa-2 {
        height: 50px;
    }

    .link-mapa-2 img {
        width: 37.5px;
        margin: 5px;
    }

    .link-mapa-4 {
        height: 50px;
        width: 245px;
    }

    .link-mapa-5 {
        margin: 15px;
    }

    .cartilhas {
        margin: auto;
        padding: 60px 0;
    }

    .servicos div {
        margin: 10px 0;
    }

    .servicos a {
        font-size: 28px;
    }

    .servicos a i {
        font-size: 28px;
        border-radius: 10px;
        padding: 8px 12px;
    }

    /* mapa RegularizaÃ§Ã£o */
    #menu-top {
        display: none;
    }

    #lateral-D {
        margin-right: -200px;
    }

    #etapas .card-text {
        font-size: 14px;
    }

    /* fim mapa RegularizaÃ§Ã£o */
    /* cartilha regularizaÃ§Ã£o */
    #cartilha-regularizacao {
        font-size: 10px;
    }

    .botao-next,
    .botao-prev {
        top: 4px;
        padding: 0;
    }

    .botao-next span,
    .botao-prev span {
        font-size: 6px;
    }

    /* fim cartilha regularizaÃ§Ã£o */
}

/* Menu */
.padding-off {
    padding: 0 !important;
}

.barra-header {
    background-color: whitesmoke;
}

.title-home {
    color: rgb(97, 97, 97) !important;
    text-decoration: none !important;
    font-size: 1.55rem;
    font-weight: 200px;
    font-family: Montserrat;
}

.title-home-div {
    margin: 10px;
}

.title-home-container {
    display: flex;
    justify-content: center;
}

.fale-conosco {
    color: #ffffff !important;
    text-decoration: none;
    background-color: #fccf42;
    border-radius: 15px;
    padding: 20px 50px;
    margin-right: 0px !important;
}

.hyper-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hyper-link a {
    color: #565656;
    text-decoration: none;
    transition: all linear 0.2s;
    font-size: 1.5rem;
    margin-right: 20px;
    font-family: Montserrat;
}

.ir-para {
    font-size: 1rem !important;
}

.img-title img {
    width: 100px;
    height: auto;
}

.img-regularizcao img {
    margin-right: 10px;
    width: 30px;
    height: auto;
}

.div-none {
    display: none !important;
}

.regularizacao-bar {
    background-color: #005494;
    font-family: Montserrat;
    font-size: 1.05rem;
}

@media (max-width: 1240px) {
    .fale-conosco {
        padding: 15px 30px;
    }

    .title-home {
        font-size: 1.4rem;
    }

    .hyper-link a {
        font-size: 1.3rem;
    }

    .img-title img {
        width: 80px;
        height: auto;
    }
}

@media (max-width: 986px) {
    .img-footer img {
        width: 100%;
        height: auto;
    }
}

@media (max-width: 740px) {
    .bar-links {
        justify-content: space-between !important;
    }

    #links-rodape {
        margin-top: 15px;
        justify-content: start !important;
    }

    .hyper-link a {
        margin: 20px !important;
    }

    .fale-conosco-div {
        margin-top: 20px !important;
    }

    .regularizacao-div {
        justify-content: center !important;
    }
}

@media (max-width: 545px) {
    .text-regularizacao {
        font-size: 0.6rem !important;
    }

    .text-regularizacao-2 {
        font-size: 0.6rem !important;
    }

    .title-home {
        font-size: 1rem;
    }

    .text-regularizacao {
        display: none;
    }

    .fale-conosco a {
        font-size: 1rem;
    }

    .ir-para-site {
        font-size: 0.8rem;
    }

    .cartilha-div {
        width: 375px;
    }

    .fale-conosco-div,
    .fale-conosco-div-consulta,
    .graficos-div,
    .brasilia-li-div {
        display: none;
    }

    .noticia-div a {
        font-size: 1.1rem !important;
    }

    .div-none {
        display: flex !important;
        justify-content: start !important;
    }

    .fale-conosco {
        margin: 0px 50px;
        padding: 10px 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .title-home-container {
        justify-content: start;
        margin: 0px !important;
        padding: 0px !important;
    }

    .titulo-response {
        display: flex;
        justify-content: center;
    }

    .parcelamento-div a {
        font-size: 1.1rem !important;
    }

    .bar-links {
        margin-top: 20px;
    }
}