.footer {
  position: relative;
  width: 100%;
  /* bottom: -100px; */
  padding-top: 8px;
  height: 350px;
  background-color: #45b97c;
  border-top: 5px solid #346399
  /* gap: 0.2rem; */
}

.copy-right-sec{
  padding: 1.8rem;
    background: #82074a;
    color: #fff;
    text-align: center;
}
.copy-right-sec a{
  color: #fcd462;
    font-weight: 500;
}

.logo-ati-nova {
  position: relative;
  width: 458px;
  height: 105.69px;
  left: 400px;
  top: 20.85px;
}

.sociais {
  margin: 50px;
  /* justify-content: space-around;  */
}

.flex-container {
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
}

.flex-start {
  /* justify-content: space-around;  */
  /* margin-left: 20px; */
  
}

.flex-item {
  /* background: tomato; */
  padding: 1px;
  width: 60px;
  height: 50px;
  margin: 10px;
  line-height: 5px;
  color: white;
  font-weight: bold;
  font-size: 2em;
  text-align: center;

  margin-top: 120px;
}

.midias-sociais {

}

.linkA{
  color: white;
  font-family: "Montserrat", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  text-decoration: none;
  white-space: normal
}

.dives{
  list-style: none;
  margin-top: 5px;
  white-space: normal;
  width: 250px;
}