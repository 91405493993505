.head {
    background-color: #515151;
    color: #ccc;
    padding: 10px;

}

.link {
    color: #ccc;
    background-color: transparent;
    line-height: 30px;
    margin-right: 20px;
    transition: 'all linear 0.2s';
    cursor: pointer;
    text-decoration: none;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
}

.linkA {
    color: #ccc;
    background-color: 'transparent';
    transition: 'all linear 0.2s';
    cursor: 'pointer';
    text-decoration: 'none'

}

.right {
    position: absolute;
    right: 0px;
    top: 10px;
}
.left{
    margin-left: 15px;
    /* position: absolute;
    right: 0px;
    top: 0px; */
}

.separaAncoras {
    margin: 0 10px;
    color: #ccc
}