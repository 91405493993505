.justify-content-md-center {
        justify-content: center;
    align-items: center;
}

.row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.link-mapa-2 {
    height: 80px;
}

.no-margin {
    margin: 0 ;
}
.link-mapa-3 {
    background-color: #0097c0;
    width: 5px;
    height: 100%;
}